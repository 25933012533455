import React, { useState, useEffect } from "react";
import Content from "../../../layout/content/Content";
import Head from "../../../layout/head/Head";
import {
  Button,
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Icon,
  PreviewCard,
  BlockDes,
} from "../../../components/Component";
import { Nav, NavItem, NavLink, TabContent, TabPane } from "reactstrap";
import { useLocation, useNavigate } from "react-router-dom";
import { getCookie } from "../../../utils/Utils";
import classnames from "classnames";
import { ShimmerTable } from "react-shimmer-effects";

const ManageProfiles = () => {
  const [loading, setLoading] = useState(false);
  const [companyData, setCompanyData] = useState(null);
  const [activeIconTab, setActiveIconTab] = useState("1");
  const { search } = useLocation();
  const company_id = new URLSearchParams(search).get("id");
  const navigate = useNavigate();

  const toggleIconTab = (icontab) => {
    if (activeIconTab !== icontab) setActiveIconTab(icontab);
  };

  const fetchCompanyData = async () => {
    setLoading(true);
    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/admin/get-company-details?company_id=${company_id}`,
        {
          method: "POST",
          headers: {
            authToken: getCookie("authToken"),
          },
        }
      );

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const responseData = await response.json();
      console.log("Response Data:", responseData);

      if (responseData?.data) {
        setCompanyData(responseData.data);
      } else {
        console.log("No data found");
        setCompanyData({});
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (company_id) {
      fetchCompanyData();
    }
  }, [company_id]);

  if (loading) {
    return (
      <div className="p-1">
        <ShimmerTable row={5} />
      </div>
    );
  }
  
  if (!companyData) {
    return <div>No data found</div>;
  }

  const companyDetails = companyData?.company_details?.find(
    (company) => company.id === Number(company_id)
  );

  if (!companyDetails) {
    return <div>No company details found for company ID: {company_id}</div>;
  }
  const firstThreeCompletedEvents = companyData?.first_three_completed_events || [];
  const PreferablePaymentTypes = companyData?.preferable_payment_type || [];

  return (
    <React.Fragment>
      <Head title="User Details - Regular" />
      {loading ? (
          <div className="p-1">
            <ShimmerTable row={5} />
          </div>
        ) : 
        (
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle tag="h3" page>
                {companyDetails.username}{/*/{" "}
                 <strong className="text-primary small">
                  {companyDetails.company_name}
                </strong> */}
              </BlockTitle>
              <BlockDes className="text-soft">
                <ul className="list-inline">
                  <li>
                    User ID:{" "}
                    <span className="text-base">{companyDetails.id}</span>
                  </li>
                </ul>
              </BlockDes>
            </BlockHeadContent>
            <BlockHeadContent>
              <Button color="primary" onClick={() => navigate(-1)}>
                <Icon name="arrow-left" />
                <span>Back</span>
              </Button>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>

        <Block size="lg">
          <PreviewCard>
            <Nav tabs>
              <NavItem className="p-2 fs-6 fw-medium">
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classnames({ active: activeIconTab === "1" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("1");
                  }}
                >
                  <Icon name="user" className="fs-5" />{" "}
                  <span>Personal Information</span>
                </NavLink>
              </NavItem>
              <NavItem className="p-2 fs-6 fw-medium">
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classnames({ active: activeIconTab === "2" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("2");
                  }}
                >
                  <Icon name="calendar-check" className="fs-5" />{" "}
                  <span>Events</span>
                </NavLink>
              </NavItem>
              <NavItem className="p-2 fs-6 fw-medium">
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classnames({ active: activeIconTab === "3" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("3");
                  }}
                >
                  <Icon name="coin-alt" className="fs-5" />{" "}
                  <span>Payments</span>
                </NavLink>
              </NavItem>
              <NavItem className="p-2 fs-6 fw-medium">
                <NavLink
                  tag="a"
                  href="#tab"
                  className={classnames({ active: activeIconTab === "4" })}
                  onClick={(ev) => {
                    ev.preventDefault();
                    toggleIconTab("4");
                  }}
                >
                  <Icon name="grid-fill" className="fs-5" />{" "}
                  <span>Other</span>
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeIconTab}>
            <TabPane tabId="1">
                  <div className="card-inner">
                    <Block>
                      <BlockHead>
                        <BlockTitle tag="h5">Personal Information</BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label"> User Name</span>
                            <span className="profile-ud-value">{companyDetails.username}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Company Name</span>
                            <span className="profile-ud-value">{companyDetails.company_name}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Email Address</span>
                            <span className="profile-ud-value">{companyDetails.email}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Mobile Number</span>
                            <span className="profile-ud-value">{companyDetails.phone_number}</span>
                          </div>
                        </div>
                      </div>
                    </Block>
                    <Block>
                      <BlockHead className="nk-block-head-line">
                        <BlockTitle tag="h6" className="overline-title text-base">
                          Additional Information
                        </BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Register Date</span>
                            <span className="profile-ud-value">{companyDetails.created_at}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Address</span>
                            <span className="profile-ud-value">
                                {companyDetails.address_1 || companyDetails.address_2
                                 ? `${companyDetails.address_1 || ""}${companyDetails.address_1 && companyDetails.address_2 ? ", " : ""}
                                 ${companyDetails.address_2 || ""}`
                                 : "N/A"}
                            </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Website</span>
                            <span className="profile-ud-value">
                              <a href="https://ipartydjcrm.com/">{companyDetails.website ||"N/A" }</a>
                            </span>
                          </div>
                        </div>
                        {/* <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Nationality</span>
                            <span className="profile-ud-value">United States</span>
                          </div>
                        </div> */}
                      </div>
                    </Block>
                  </div>
                </TabPane>
                <TabPane tabId="2">
                  <div className="card-inner">
                    <Block>
                      <BlockHead>
                        <BlockTitle tag="h5">Event Information</BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Total Events</span>
                            <span className="profile-ud-value">{companyData?.total_events_count || "0"}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Completed Events</span>
                            <span className="profile-ud-value">{companyData?.total_completed_events_count || "0"}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Pending Events</span>
                            <span className="profile-ud-value">{companyData?.pending_events}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Incompleted Events</span>
                            <span className="profile-ud-value">{companyData?.incompleted_events || '0'}</span>
                          </div>
                        </div>
                      </div>
                    </Block>
                    <Block>
                      <BlockHead className="nk-block-head-line">
                        <BlockTitle tag="h6" className="overline-title text-base">
                          Additional Information
                        </BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Most Successfull Events</span>
                            <span className="profile-ud-value">
                            {firstThreeCompletedEvents.length > 0 ? (
                              firstThreeCompletedEvents.map((event, index) => (
                                <div key={index}>{event}</div>
                              ))
                            ) : (
                              <div>No completed events</div>
                            )}
                          </span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Event Type Count</span>
                            <span className="profile-ud-value">{companyData?.total_event_types_count ||'0'}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Upcoming Event</span>
                            <span className="profile-ud-value">{companyData?.upcoming_event_revenue || '0'}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Average Monthly Events</span>
                            <span className="profile-ud-value">{companyData?.avearge_monthly_completed_events || '0'}</span>
                          </div>
                        </div>
                      </div>
                    </Block>
                  </div>
                </TabPane>
                <TabPane tabId="3">
                  <div className="card-inner">
                    <Block>
                      <BlockHead>
                        <BlockTitle tag="h5">Payment Information</BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Total Revenue</span>
                            <span className="profile-ud-value">{companyData?.total_venue_count ||'0'}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label"> Collected Revenue</span>
                            <span className="profile-ud-value">{companyData?.total_event_collected_revenue ||'0'}</span>
                          </div>
                        </div>
                       
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Upcoming Events Revenue</span>
                            <span className="profile-ud-value">{companyData?.upcoming_event_revenue ||'0'}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Pending Amount</span>
                            <span className="profile-ud-value">{companyData?.pending_event_amount ||'0'}</span>
                          </div>
                        </div>
                      </div>
                    </Block>
                    <Block>
                      <BlockHead className="nk-block-head-line">
                        <BlockTitle tag="h6" className="overline-title text-base">
                          Additional Information
                        </BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Preferable Payment Types</span>
                            <span className="profile-ud-value">
                              { 
                               PreferablePaymentTypes.length > 0 ? (
                               PreferablePaymentTypes.map((event, index) => (
                               <span key={index} className="payment-type">
                                 {event}{index < PreferablePaymentTypes.length - 1 && ", "}
                               </span>
                                ))
                                 ) : (
                                 <div>N/A</div>
                                 )}
                             </span>
                          
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Most Used Payment Types</span>
                            <span className="profile-ud-value">{companyData.most_used_payment_type || '0'}</span>
                          </div>
                        </div>
                      </div>
                    </Block>
                  </div>
                </TabPane>
                <TabPane tabId="4">
                  <div className="card-inner">
                    <Block>
                      <BlockHead>
                        <BlockTitle tag="h5">Other Information</BlockTitle>
                      </BlockHead>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Total Client</span>
                            <span className="profile-ud-value">{companyData.total_clients_count || '0'}</span>
                          </div>
                        </div>
                      </div>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Total Employee</span>
                            <span className="profile-ud-value">{companyData.total_employee_count || '0'}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Calculated Employee Wage</span>
                            <span className="profile-ud-value">{companyData.total_wage || '0'}</span>
                          </div>
                        </div>
                      </div>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Total Venues</span>
                            <span className="profile-ud-value">{companyData.total_venue_count || '0'}</span>
                          </div>
                        </div>
                      </div>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Total Venders</span>
                            <span className="profile-ud-value">{companyData.total_vendor_count || '0'}</span>
                          </div>
                        </div>
                      </div>
                      <div className="profile-ud-list">
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Total Packages</span>
                            <span className="profile-ud-value">{companyData.total_packages_count || '0'}</span>
                          </div>
                        </div>
                        <div className="profile-ud-item">
                          <div className="profile-ud wider">
                            <span className="profile-ud-label">Highest Package</span>
                            <div className="d-flex profile-ud-value">
                            <span className="profile-ud-value">{companyData.highest_package_name || 'N/A'}</span>
                            <span className="mx-3">-</span>
                            <span>{companyData.highest_package_price || '0'}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Block>
                   
                  </div>
                </TabPane>
            </TabContent>
          </PreviewCard>
        </Block>
      </Content>
        )}
    </React.Fragment>
  );
};

export default ManageProfiles;
