import React, { useState, useEffect } from "react";
import Head from "../../../layout/head/Head";
import Content from "../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockBetween,
  BlockHeadContent,
  BlockTitle,
  BlockDes,
  Icon,
  Button,
  Row,
  ProjectCard,
  UserAvatar,
  Col,
  PaginationComponent,
} from "../../../components/Component";
import { features } from "./ProjectData";
import { MakeApiCall, findUpper, getCookie } from "../../../utils/Utils";
import { DropdownMenu, DropdownToggle, UncontrolledDropdown, DropdownItem, Badge } from "reactstrap";
import FormModal from "./FormModal";
import Swal from "sweetalert2";
import { toast } from "react-toastify";

const ProjectCardPage = () => {
  const toggleLg = () => setModalLg(!modalLg);
  const [isOpen, setisOpen] = useState(false);

  const toggle = () => {
    setisOpen(!isOpen);
  };
  const [modalLg, setModalLg] = useState(false);
  const [sm, updateSm] = useState(false);
  const [modal, setModal] = useState({
    add: false,
    edit: false,
  });
  const [editId, setEditedId] = useState();
  const [data, setData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  //const [itemPerPage] = useState(6);
  const [paidValues, setPaidValues] = useState({});
  const [loading, setLoading] = useState(false);
  const [planStates, setPlanStates] = useState({});

  useEffect(() => {
    fetchData();
    return () => {};
  }, []);
 
  const fetchData = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/get-new-subscription-plan`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          authToken: getCookie("authToken"),
        },
        body: formData,
        
      });
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
      const responseData = await response.json();
      console.log('response',responseData);
      
      // setData(responseData?.data);
      if (Array.isArray(responseData?.data)) {
        setData(responseData?.data);
      } else {
        console.error("Expected an array, got", responseData?.data);
        setData([]); // Ensure data is always an array
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };
  const deleteData = async (id) => {
    try {
      Swal.fire({
        title: "Are you sure to delete the plan?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      }).then((result) => {
        if (result.isConfirmed) {
          Swal.fire("Deleted!", "Your file has been deleted.<b><h5>Wait for the page refreshing</h5></b>", "success");
          const formData = new FormData();
          formData.append("id", id);
          MakeApiCall(`api/v1/admin/delete-plan`, formData);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          fetchData();
        }
      });
    } catch (error) {
      console.log("Error deleting plan:", error);
    }
  };
  const [formData, setFormData] = useState({
      name:" ",
      monthly_price: '',
      yearly_price:0,
      monthly_discount:0,
      yearly_discount:0,
      monthly_discount_price:0,
      yearly_discount_price:0,
      business_description:"",
      photos:0,
      videos:0,
      youtube_link:'0',
      mixes:0,
      social_media_urls:0,
      service_list:'1',
      //customize_contact_form:0,
      go_live_streaming:"",
      monthly_duration:'m',
      yearly_duration:'y',
      monthly_action:1,
      yearly_action:1,
      id:'',
  });
  const [editFormData, setEditFormData] = useState({
    name:" ",
      monthly_price:0,
      yearly_price:0,
      monthly_discount:0,
      yearly_discount:0,
      monthly_discount_price:0,
      yearly_discount_price:0,
      business_description:"",
      photos:0,
      videos:0,
      youtube_link:'0',
      mixes:0,
      social_media_urls:0,
      service_list:'1',
      //customize_contact_form:0,
      go_live_streaming:"",
      monthly_duration:'m',
      yearly_duration:'y',
      monthly_action:1,
      yearly_action:1,
      id:'',
  });  


  const CloseButton = () => {
    return (
      <span className="btn-trigger toast-close-button" role="button">
        <Icon name="cross"></Icon>
      </span>
    );
  };
  // Function to reset the form
  const resetForm = () => {
    setFormData({
      name:" ",
      monthly_price:'',
      yearly_price:0,
      monthly_discount:0,
      yearly_discount:0,
      monthly_discount_price:0,
      yearly_discount_price:0,
      business_description:"",
      photos:0,
      videos:0,
      youtube_link:'0',
      mixes:0,
      social_media_urls:0,
      service_list:'1',
      //customize_contact_form:0,
      go_live_streaming:"",
      monthly_duration:'m',
      yearly_duration:'y',
      monthly_action:1,
      yearly_action:1,
      id:'',
    });
  };
  const closeModal = () => {
    setModal({ add: false });
    resetForm();
  };
  const closeEditModal = () => {
    setModal({ edit: false });
    resetForm();
  };
  const onFormSubmit = async (sData) => {
    setLoading(true);
    const {
      name,
      monthly_price,
      yearly_price,
      monthly_discount,
      yearly_discount,
      monthly_discount_price,
      yearly_discount_price,
      business_description,
      photos,
      videos,
      youtube_link,
      mixes,
      social_media_urls,
      service_list,
      //customize_contact_form,
      go_live_streaming,
      monthly_duration,
      yearly_duration,
      monthly_action,
      yearly_action,
      id,
    } = sData;

    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append('monthly_price',monthly_price.toFixed(2));
      formData.append('yearly_price',yearly_price);
      formData.append('monthly_discount',monthly_discount);
      formData.append('yearly_discount',yearly_discount);
      formData.append('monthly_discount_price',monthly_discount_price);
      formData.append('yearly_discount_price',yearly_discount_price)
      formData.append("business_description", business_description);
      formData.append("photos", photos);
      formData.append("videos", videos);
      formData.append("youtube_link",youtube_link);
      formData.append("mixes", mixes);
      formData.append("social_media_urls", social_media_urls);
      formData.append("service_list", service_list);
      // formData.append("customize_contact_form",  customize_contact_form);
      formData.append("go_live_streaming", go_live_streaming);
      formData.append("monthly_duration", monthly_duration);
      formData.append("yearly_duration", yearly_duration);
      formData.append('monthly_action',monthly_action);
      formData.append('yearly_action',yearly_action);
      formData.append('id',id)
      const planData = await MakeApiCall(`api/v1/admin/add-update-subscription-plan`, formData);
      console.log(planData);
            
      if (planData && planData.status) {
        const submittedData = planData.data;
        setData((data) => [submittedData, ...data]);
        toast.success("Plan added successfully");
        fetchData();
      } else {
        setLoading(false);
        console.log("planUser data is invalid or missing required properties");
        toast.error(planData.message);
      }
    } catch (error) {
      toast.error("Something went to wrong!");
      console.log("Error fetching user data:", error);
    } finally {
      setLoading(false);
      resetForm();
      setModal({ add: false });
    }
  };
  const onEditSubmit = async (sData) => {
    setLoading(true);
    const {
      name,
      monthly_price,
      yearly_price,
      monthly_discount,
      yearly_discount,
      monthly_discount_price,
      yearly_discount_price,
      business_description,
      photos,
      videos,
      youtube_link,
      mixes,
      social_media_urls,
      service_list,
      //customize_contact_form,
      go_live_streaming,
      monthly_duration,
      yearly_duration,
      monthly_action,
      yearly_action,
      id,
    } = sData;
    //console.log(durationtype);
    try {
      const formData = new FormData();
      formData.append("name", name);
      formData.append('monthly_price',monthly_price);
      formData.append('yearly_price',yearly_price);
      formData.append('monthly_discount',monthly_discount);
      formData.append('yearly_discount',yearly_discount);
      formData.append('monthly_discount_price',monthly_discount_price);
      formData.append('yearly_discount_price',yearly_discount_price)
      formData.append("business_description", business_description);
      formData.append("photos", photos);
      formData.append("videos", videos);
      formData.append("youtube_link",youtube_link);
      formData.append("mixes", mixes);
      formData.append("social_media_urls", social_media_urls);
      formData.append("service_list", service_list);
      // formData.append("customize_contact_form",  customize_contact_form);
      formData.append("go_live_streaming", go_live_streaming);
      formData.append("monthly_duration", monthly_duration);
      formData.append("yearly_duration", yearly_duration);
      formData.append('monthly_action',monthly_action);
      formData.append('yearly_action',yearly_action);
      formData.append('id',id)
      const planData = await MakeApiCall(`api/v1/admin/add-update-subscription-plan`, formData);
      console.log(planData);
      
      if (planData && planData.status) {
        const submittedData = planData.data;
        toast.success("Plan updated successfully");
        window.location.reload()
      } else {
        toast.error(planData.message);
        console.log("planUser data is invalid or missing required properties");
      }
    } catch (error) {
      console.log("Error fetching user data:", error);
    } finally {
      setModal({ edit: false });
      setLoading(false);
      resetForm();
    }
  };
  const onEditClick = (id) => {
    // data.forEach((item) => {
    const item = data.find((item) => item.id === id);
    if (item) {
      setEditFormData({
        id: item.id,
        name: item.name,
        monthly_price:item.monthly_price,
        yearly_price:item.yearly_price,
        monthly_discount:item.monthly_discount,
        yearly_discount:item.yearly_discount,
        monthly_discount_price:item.monthly_discount_price,
        yearly_discount_price:item.yearly_discount_price,
        business_description:item.business_description,
        photos:item.photos,
        videos:item.videos,
        youtube_link:item.youtube_link,
        mixes:item.mixes,
        social_media_urls:item.social_media_urls,
        service_list:item.service_list,
        go_live_streaming:item.go_live_streaming,
        monthly_duration:item.monthly_duration,
        yearly_duration:item.yearly_duration,
        monthly_action:item.monthly_action,
        yearly_action:item.yearly_action,
      });
      setModal({ edit: true }, { add: false });
      setEditedId(id);
      //console.log(item.monthly_price);
      
    }      
  };
  // Pagination
  const itemPerPage = 6;  
  const indexOfLastItem = currentPage * itemPerPage;
  const indexOfFirstItem = indexOfLastItem - itemPerPage;
  const currentItems = data.length > 0 && data !== undefined ? data.slice(indexOfFirstItem, indexOfLastItem) : [];
  const paginate = (pageNumber) => setCurrentPage(pageNumber);
  return (
    <React.Fragment>
      <Head title="Project Card"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle page>Create Your Plan</BlockTitle>
              {/* <BlockDes className="text-soft">You have total {data?.length || 0} plan</BlockDes> */}
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className={`btn-icon btn-trigger toggle-expand me-n1 ${sm ? "active" : ""}`}
                  onClick={() => updateSm(!sm)}
                >
                  <Icon name="menu-alt-r"></Icon>
                </Button>
                <div className="toggle-expand-content" style={{ display: sm ? "block" : "none" }}>
                  <ul className="nk-block-tools g-3">
                    <li className="nk-block-tools-opt" onClick={() => setModal({ add: true })}>
                      <Button color="primary">
                        <Icon name="plus"></Icon>
                        <span>Create New Plan</span>
                      </Button>
                    </li>
                  </ul>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {/* {data?.length === 0 ? (
          <div className="fs-2 fw-bolder justify-content-end d-flex flex-column h-225px text-center">
            No Plan is available
          </div>
        ) : loading ? (
          <h1>loading...</h1>
        ) : ( */}
        {loading ? (
          <div className="loading-message fs-2 fw-bolder justify-content-center d-flex flex-column h-225px text-center">
            Loading...
          </div>
        ) : data?.length === 0 ? (
          <div className="fs-2 fw-bolder justify-content-center d-flex flex-column h-225px text-center">
            No Plan is available
          </div>
        ) : (

          <Block>
  <Row className="g-gs">
    {currentItems &&
      currentItems.map((item) => {
        return (
          <>
            {/* Monthly Plan Card */}
            {item.monthly_action == "1" && (
              <Col sm="6" lg="4" xxl="3" key={`${item.id}-yearly`}>
                <ProjectCard>
                <div className="project-head">
                          <a
                            href="#title"
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                            className="project-title"
                          >
                            <UserAvatar className="sq" theme={item.avatarClass} text={findUpper(item.name)} />
                            <div className="project-info">
                              <h5 className="title fs-5">{item.name}</h5>
                            </div>
                          </a>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              className="dropdown-toggle btn btn-sm btn-icon btn-trigger mt-n1 me-n1"
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt no-bdr">
                                <li onClick={() => onEditClick(item.id)}>
                                  <DropdownItem
                                    tag="a"
                                    href="#edit"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                  >
                                    <Icon name="edit"></Icon>
                                    <span>Edit Plan</span>
                                  </DropdownItem>
                                </li>
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#remove"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      deleteData(item.id);
                                      // handleAdvanced3()
                                    }}
                                  >
                                    <Icon name="trash"></Icon>
                                    <span>Remove Plan</span>
                                  </DropdownItem>
                                </li>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                  {item?.monthly_discount !== "NaN" &&
                    item?.monthly_discount !== "0" && (
                      <div className="d-flex w-100 justify-end me-3">
                        <Badge color="primary">
                          Save up to {item?.monthly_discount}%
                        </Badge>
                      </div>
                    )}
                  <ul className="team-statistics py-1">
                    <li>
                      <span className="text-primary fw-medium">Monthly</span>
                      <span className="text-primary fw-medium">
                        Plan Duration
                      </span>
                    </li>
                    <li>
                    <span className="d-flex justify-center">
                       {item.monthly_price !== item.monthly_discount_price ? (
                      <>
                          <span className="fw-medium fs-5 text-body text-decoration-line-through">
                            ${item.monthly_price}
                          </span>
                          {item.monthly_discount_price && (
                          <span className="fw-medium fs-5 text-primary ms-1 shadow px-1">
                              ${parseFloat(item.monthly_discount_price).toFixed(2)}
                          </span>
                        )}
                       </>
                        ) : (
                          <span className="fw-medium fs-5 text-primary">
                            ${parseFloat(item.monthly_discount_price).toFixed(2)}
                           </span>
                          )}
                    </span>

                      
                      <span className="text-primary fw-medium">
                        Rate Of Plan
                      </span>
                    </li>
                  </ul>
                  <div className="d-flex justify-content-between mt-3">
                          <div className="fs-4 fw-bold">Plan Features & Their Limits</div>
                        </div>
                        <div className="d-flex flex-column py-1">
                          <div className="d-flex justify-content-between my-1">
                            <span>Business Description :</span>
                            <span>{item?.business_description}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Photos :</span>
                            <span>{item?.photos}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Upload Video Limit :</span>
                            <span>{item?.videos}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Youtube Videos Links :</span>
                            <span>{item?.youtube_link}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Mixers :</span>
                            <span>{item?.mixes}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Social Media URL's :</span>
                            <span>{item?.social_media_urls}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Service Listings :</span>
                            <span>{item?.service_list}</span>
                          </div>
                          {/* <div className="d-flex justify-content-between my-1">
                            <span>Cutomized Contact Form :</span>
                            <span>{item?.customize_contact_form}</span>
                          </div> */}
                          <div className="d-flex justify-content-between my-1">
                            <span>Go-Live Streaming :</span>
                            <span>{item?.go_live_streaming}</span>
                          </div>
                        </div>
                </ProjectCard>
              </Col>
            )}

            {/* Yearly Plan Card */}
            {item.yearly_action == "1" && (
              <Col sm="6" lg="4" xxl="3" key={`${item.id}-yearly`}>
                <ProjectCard>
                <div className="project-head">
                          <a
                            href="#title"
                            onClick={(ev) => {
                              ev.preventDefault();
                            }}
                            className="project-title"
                          >
                            <UserAvatar className="sq" theme={item.avatarClass} text={findUpper(item.name)} />
                            <div className="project-info">
                              <h5 className="title fs-5">{item.name}</h5>
                            </div>
                          </a>
                          <UncontrolledDropdown>
                            <DropdownToggle
                              tag="a"
                              className="dropdown-toggle btn btn-sm btn-icon btn-trigger mt-n1 me-n1"
                            >
                              <Icon name="more-h"></Icon>
                            </DropdownToggle>
                            <DropdownMenu end>
                              <ul className="link-list-opt no-bdr">
                                <li onClick={() => onEditClick(item.id)}>
                                  <DropdownItem
                                    tag="a"
                                    href="#edit"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                    }}
                                  >
                                    <Icon name="edit"></Icon>
                                    <span>Edit Plan</span>
                                  </DropdownItem>
                                </li>
                                <li>
                                  <DropdownItem
                                    tag="a"
                                    href="#remove"
                                    onClick={(ev) => {
                                      ev.preventDefault();
                                      deleteData(item.id);
                                      // handleAdvanced3()
                                    }}
                                  >
                                    <Icon name="trash"></Icon>
                                    <span>Remove Plan</span>
                                  </DropdownItem>
                                </li>
                              </ul>
                            </DropdownMenu>
                          </UncontrolledDropdown>
                        </div>
                  {item?.yearly_discount !== "NaN" &&
                    item?.yearly_discount !== "0" && (
                      <div className="d-flex w-100 justify-end me-3">
                        <Badge color="primary">
                          Save up to {item?.yearly_discount}%
                        </Badge>
                      </div>
                    )}
                  <ul className="team-statistics py-1">
                    <li>
                      <span className="text-primary fw-medium">Yearly</span>
                      <span className="text-primary fw-medium">
                        Plan Duration
                      </span>
                    </li>
                    <li>
                    <span className="d-flex justify-center">
                       {item.yearly_price !== item.yearly_discount_price ? (
                      <>
                          <span className="fw-medium fs-5 text-body text-decoration-line-through">
                            ${item.yearly_price}
                          </span>
                          {item.yearly_discount_price && (
                          <span className="fw-medium fs-5 text-primary ms-1 shadow px-1">
                              ${parseFloat(item.yearly_discount_price).toFixed(2)}
                          </span>
                        )}
                       </>
                        ) : (
                          <span className="fw-medium fs-5 text-primary">
                            ${parseFloat(item.yearly_discount_price).toFixed(2)}
                           </span>
                          )}
                    </span>

                      
                      <span className="text-primary fw-medium">
                        Rate Of Plan
                      </span>
                    </li>
                  </ul>
                  <div className="d-flex justify-content-between mt-3">
                          <div className="fs-4 fw-bold">Plan Features & Their Limits</div>
                        </div>
                        <div className="d-flex flex-column py-1">
                          <div className="d-flex justify-content-between my-1">
                            <span>Business Description :</span>
                            <span>{item?.business_description}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Photos :</span>
                            <span>{item?.photos}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Upload Video Limit :</span>
                            <span>{item?.videos}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Youtube Videos Links :</span>
                            <span>{item?.youtube_link}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Mixers :</span>
                            <span>{item?.mixes}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Social Media URL's :</span>
                            <span>{item?.social_media_urls}</span>
                          </div>
                          <div className="d-flex justify-content-between my-1">
                            <span>Service Listings :</span>
                            <span>{item?.service_list}</span>
                          </div>
                          {/* <div className="d-flex justify-content-between my-1">
                            <span>Cutomized Contact Form :</span>
                            <span>{item?.customize_contact_form}</span>
                          </div> */}
                          <div className="d-flex justify-content-between my-1">
                            <span>Go-Live Streaming :</span>
                            <span>{item?.go_live_streaming}</span>
                          </div>
                        </div>
                </ProjectCard>
              </Col>
            )}
          </>
        );
      })}
  </Row>
  {data?.length === 0 ? null : (
    <div className="mt-3">
      <PaginationComponent
        itemPerPage={itemPerPage}
        totalItems={data?.length || 0}
        paginate={paginate}
        currentPage={currentPage}
      />
    </div>
  )}
</Block>
        )}

        <FormModal
          modal={modal.add}
          modalType="add"
          formData={formData}
          setFormData={setFormData}
          closeModal={closeModal}
          onSubmit={(formData) => onFormSubmit(formData)}
          setLoading={setLoading}
          loading={loading}
    />
        <FormModal
          modal={modal.edit}
          modalType="edit"
          formData={editFormData}
          setFormData={setEditFormData}
          closeModal={closeEditModal}
          onSubmit={onEditSubmit}
          setLoading={setLoading}
          loading={loading}
        />
      </Content>
    </React.Fragment>
  );
};
export default ProjectCardPage;
