import React, { useEffect, useState } from "react";
import { Block, BlockHead, BlockHeadContent, BlockTitle, PreviewCard,Icon,Button, DataTableRow} from "../../components/Component";
import Content from "../../layout/content/Content";
import { getCookie } from "../../utils/Utils";
import CustomizeReactDataTable from "./CustomizeReactDataTable";
import { toast } from "react-toastify";
import CompanyListModels from "../prebuilt/Company List/CompanyListModels";
import { useNavigate } from "react-router";
import { checkLogin, setAuthTokenCookie } from "../../http/post/postApi";
import PuffLoader from "react-spinners/PuffLoader";
import Swal from "sweetalert2";
import PaymentListModels from "./PaymentListModels";
import Switch from "react-switch";
export const NotAvailable = () => <div style={{ textAlign: "center" }}>----</div>;

const Payment = () => {
 
  const [PaymentData, setPaymentData] = useState(null);
  const [pageNo, setPageNo] = useState(0);
  const [recordLimit, setRecordLimit] = useState(10); 

  const [search, setSearch] = useState("");
  const [loading, setLoading] = useState("");
  const [totalRecords, setTotalRecords] = useState(0);
  const [phoneValue, setPhoneValue] = useState();
  const [editPhone, setEditPhone] = useState();
  const [, setLoadingRow] = useState(false);
  const [, setCompanyId] = useState(null);
  const [, setFiles] = useState([]);

  const fetchData = async () => {
    setLoading(true);
    try {
      const formData = new FormData();
      formData.append("page", pageNo);
      formData.append("limit", recordLimit);
      formData.append("search", search);
      //console.log('page No.', pageNo);

      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/admin/get-payment-setting-list`,
        {
          method: "POST",
          headers: { authToken: getCookie("authToken") },
          body: formData,
        }
      );
      const responseData = await response.json();
      if (responseData?.data) {
        setPaymentData(responseData?.data?.PaymentSetting);
        setTotalRecords(responseData?.data?.totalRecords|| 0);
      } else {
        setPaymentData([]);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
  }, [pageNo, recordLimit, search]);

  
  const deleteData = async (id) => {
    try {

      const paymentExists = PaymentData.some(Payment => Payment.id === id);
      if (!paymentExists) {
        Swal.fire("Error!", "Payment type not found in the list.", "error");
        return;
      }

      const result = await Swal.fire({
        title: "Are you sure to delete the payment type?",
        text: "You won't be able to revert this!",
        icon: "warning",
        showCancelButton: true,
        confirmButtonText: "Yes, delete it!",
      });

      if (result.isConfirmed) {
        const response = await fetch(
          `${process.env.REACT_APP_API_URL}api/v1/admin/delete-payment-setting`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              authToken: getCookie("authToken"),
            },
            body: JSON.stringify({ id }),
          }
        );

        const responseData = await response.json();

        if (responseData.status && responseData.message === 'Payment setting deleted successfully') {
          setPaymentData((prevData) => prevData.filter((item) => item.id !== id));
          Swal.fire("Deleted!", "The payment type record has been deleted permanently.", "success");
        } else {
          throw new Error(`Failed to delete payment type. Status: ${response.status}`);
        }
      }
    } catch (error) {
      console.error("Error deleting payment type:", error);
      Swal.fire("Error!", error.message, "error");
    }
  };
  const handleDelete = (row) => {
    deleteData(row.id);
  };
  const handlePaymentStatusToggle = async (row, checked) => {
    const updatedStatus = checked ? 1 : 0;
  
    try {
      const formData = new FormData();
      formData.append("id", row.id);
      formData.append("action", updatedStatus);
  
      const response = await fetch(
        `${process.env.REACT_APP_API_URL}api/v1/admin/change-status-payment-setting`,
        {
          method: "POST",
          headers: {
            authToken: getCookie("authToken"),
          },
          body: formData,
        }
      );
  
      const responseData = await response.json();
      
      if (responseData?.status) {
        setPaymentData((prevData) =>
          prevData.map((item) =>
            item.id === row.id ? { ...item, action: updatedStatus } : item
          )
        );
  
        toast.success("Payment status updated successfully");
      } else {
        throw new Error(responseData.message || "Failed to update payment status");
      }
    } catch (error) {
      console.error("Error toggling payment status:", error);
  
      setPaymentData((prevData) =>
        prevData.map((item) =>
          item.id === row.id ? { ...item, action: row.action } : item
        )
      );
  
      toast.error("Error updating status. Please try again.");
    }
  };
  
  
  
  
const dataTableColumns = [
    {
      name: "Sr.No",
      selector: (row, index) => (pageNo * recordLimit) + index + 1
    },
    {
      name: "Payment Type",
      selector: (row) => row?.payment_type || <NotAvailable />,
    },
    {
      name: "Environment Status",
      selector: (row) => row?.environment || <NotAvailable />,
    },
    {
      name: "Payment Status",
      selector: (row) => (
        <Switch
          checked={row?.action == 1}
          onChange={(checked) => handlePaymentStatusToggle(row, checked)}
          onColor="#77c96b"
          offColor="#d3d3d3"
          onHandleColor="#36ba00"
          handleDiameter={15}
          width={40}
          height={20}
          checkedIcon={false}
          uncheckedIcon={false}
          boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
          activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        />
       
      ),
    },
    {
        name: "Action",
        cell: (row) => (
          <div className="d-flex">
            <button
              className="btn btn-sm border-0"
              onClick={(ev) => {
                ev.preventDefault();
                onEditClick(row?.id);
                toggle("edit");
              }}
              
            >
              <Icon name="edit" />
              
            </button>
            <button
              className="btn btn-sm border-0 me-2"
              onClick={(ev) => {
                ev.preventDefault();
                handleDelete(row);
              }}
              id="deleteMessage"
            >
              <Icon name="trash" />
            </button>
          </div>
        ),
      }, 
  ];
  
  const rowClass = (row) => {
    return row?.is_suspend === "1" ? "bg-danger text-white" : "";
  };
  
  
  const [editId, setEditedId] = useState();

  const [view, setView] = useState({
    edit: false,
    add: false,
    details: false,
  });
  const toggle = (type) => {
    setView({
      edit: type === "edit" ? true : false,
      add: type === "add" ? true : false,
    });
  };
  const [formData, setFormData] = useState({
    id: '',
    payment_type: '',
    environment: 'Sandbox',
    client_key: '',
    secret_key: '',
    environment_action: 0,
  });
  
  const resetForm = () => {
    setFormData({
      id: '',
      payment_type: '',
      environment: 'Sandbox',
      client_key: '',
      secret_key: '',
      environment_action: 0,
    });
  };
  
  const onFormSubmit = async (form) => {
    setLoading(true);
    const { id, payment_type, environment, client_key, secret_key, environment_action } = form;
  
    try {
      const isDuplicate = PaymentData.some(
        (item) =>
          item.payment_type === payment_type && 
          item.environment === environment &&
          item.id !== id // Ignore if the same record is being updated
      );
  
      if (isDuplicate) {
        toast.error("Payment type already exist.");
        setLoading(false);
        return;
      }
  
      const formData = new FormData();
      formData.append("id", id);
      formData.append("payment_type", payment_type);
      formData.append("environment", environment);
      formData.append("client_key", client_key);
      formData.append("secret_key", secret_key);
      formData.append("environment_action", environment_action);
  
      const response = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/add-update-payment-setting`, {
        method: "POST",
        body: formData,
        headers: {
          authToken: getCookie("authToken"),
        },
      });
  
      const PaymentDataResponse = await response.json();
      if (PaymentDataResponse && PaymentDataResponse.status) {
        toast.success(`${payment_type}  Added Successfully`);
        fetchData();
        resetForm();
        setView({ edit: false });
      } else {
        toast.error(PaymentDataResponse?.errors?.[0]);
      }
    } catch (error) {
      toast.error("Error occurred while submitting the form.");
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };
  
  const onFormCancel = () => {
    setView({ edit: false, add: false, details: false });
    resetForm();
  };

  const onEditSubmit = async (form) => {
    //console.log("hello!");
    setLoading(true);
    const {  id, payment_type, environment, client_key, secret_key, environment_action } = form;
    try {
      const formData = new FormData();
      formData.append("id", id);
      formData.append("payment_type", payment_type);
      formData.append("environment", environment);
      formData.append("client_key", client_key);
      formData.append("secret_key", secret_key);
      formData.append("environment_action", environment_action);
      const PaymentData = await fetch(`${process.env.REACT_APP_API_URL}api/v1/admin/add-update-payment-setting`, {
        method: "POST",
        headers: {
          authToken: getCookie("authToken"),
        },
        body: formData,
      }).then((res) => res.json());
      console.log(PaymentData);
      if (PaymentData && PaymentData.status === true) {
        console.log(PaymentData.status);
        const submittedData = PaymentData?.data;
        console.log(PaymentData?.data);
        toast.success(`${payment_type}  Updated Successfully`);
        fetchData();
        resetForm();
        setView({ edit: false });
        setLoading(false);
      } else {
        console.log(PaymentData?.data);

        toast.error(PaymentData.message);
        console.log("data is invalid or missing required properties");
        setLoading(false);
      }
    } catch (error) {
      toast.error(PaymentData.message);
      console.log("Error fetching user data:", error);

      setLoading(false);
    }
  };
  const onEditClick = (id) => {
    const item = PaymentData.find((item) => item.id === id);
    if (item) {
      setFormData({
        id: item.id,
        payment_type: item.payment_type,
        environment: item.environment || 'Sandbox', 
        client_key: item.client_key,
        secret_key: item.secret_key,
        environment_action: item.environment === 'Live' ? 1 : 0,
      });
    }
    setEditedId(id);
    setView({ add: false, edit: true });
  };
  

  return (
    <>
      <Content>
        <Block size="lg">
          <BlockHead>
            <BlockHeadContent>
              <div className="d-flex flex-row align-center justify-between">
                <div>
                <BlockTitle tag="h4">Payment Setting</BlockTitle>
                
              </div>
                <div>
                <BlockHeadContent>
                <div className="nk-block-tools g-3">
                  <li className="nk-block-tools-opt">
                  <Button
                   className="toggle btn-icon d-md-none"
                   color="primary"
                   onClick={() => {
                   toggle("add");
                  }}
                  >
                  <Icon name="plus"></Icon>
                  </Button>
                  <Button
                   className="toggle d-none d-md-inline-flex"
                   color="primary"
                   onClick={() => {
                   toggle("add");
                   resetForm();
                  }}
                  >
                  <Icon name="plus"></Icon>
                  <span>Add Payment</span>
                 </Button>
                </li>
              </div>
            </BlockHeadContent>
                </div>
              </div>
            </BlockHeadContent>
          </BlockHead>
          <PreviewCard>
            <CustomizeReactDataTable
              data={PaymentData}
              columns={dataTableColumns}
              pagination
              actions
              loading={loading}
              setSearch={setSearch}
              rowClass={rowClass}
              progressPending={loading}
              pageNo={setPageNo}
              recordLimit={setRecordLimit}
              totalRecords={totalRecords}
             activePage={pageNo || 0}
              // searchBy={PaymentData?.company_name}
            />
           
          </PreviewCard>
          
        </Block>
        <PaymentListModels
          view={view.add}
          modalType="add"
          formData={formData}
          setFormData={setFormData}
          onFormCancel={onFormCancel}
          onSubmit={(formData) => onFormSubmit(formData)}
          setLoading={setLoading}
          loading={loading}
        />
        <PaymentListModels
          view={view.edit}
          modalType="edit"
          formData={formData}
          setFormData={setFormData}
          onFormCancel={onFormCancel}
          onSubmit={onEditSubmit}
          setLoading={setLoading}
          loading={loading}
        />
      </Content>
      
    </>
  );
};

export default Payment;
