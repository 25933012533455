import React, { useEffect, useState } from "react";
import { Icon, Button, Col, Row } from "../../components/Component";
import { Modal, ModalBody } from "reactstrap";
import { useForm } from "react-hook-form";
import Select, { components } from "react-select";
import PuffLoader from "react-spinners/PuffLoader";

const Option = (props) => (
  <components.Option {...props} className="country-option">
    <img src={props.data.icon} alt="logo" className="country-logo" style={{ width: "20px", marginRight: "10px" }} />
    {props.data.label}
  </components.Option>
);

const SingleValue = ({ children, ...props }) => (
  <components.SingleValue {...props}>
    <img src={props.data.icon} alt="s-logo" className="selected-logo" style={{ width: "20px", marginRight: "10px" }} />
    {children}
  </components.SingleValue>
);

const PaymentListModels = ({ view, modalType, formData, setFormData, onFormCancel, onSubmit, loading }) => {
  const [passState, setPassState] = useState(false);
  const [confirmPassState, setConfirmPassState] = useState(false);
  const [countries, setCountries] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);

  const {
    reset,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm();

  const password = watch("password");

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  return (
    <Modal isOpen={view} toggle={onFormCancel} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          className="close"
          onClick={(ev) => {
            ev.preventDefault();
            onFormCancel();
          }}
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{modalType === "add" ? "Add Payment " : "Update Payment"}</h5>
          <div className="mt-4">
            <form noValidate onSubmit={handleSubmit(onSubmit)}>
              <Row className="g-3">
              <Col size="12">
                  <div className="form-group">
                    <label className="form-label" htmlFor="payment_type">
                      Payment Type
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      placeholder="Enter Company Name"
                      {...register("payment_type", {
                        required: "This field is required",
                      })}
                      maxLength={50}
                      value={formData.payment_type}
                      onChange={(e) => setFormData({ ...formData, payment_type: e.target.value })}
                    />
                    {errors.payment_type && <span className="invalid">{errors.payment_type.message}</span>}
                  </div>
                </Col>
                
                {/* <Col size="12">
                <label className="form-label" htmlFor="payment_type">
                      Payment Type
                    </label>
                  <select
                    name="environment"
                    value={formData.environment}
                    onChange={(e) => setFormData({ ...formData, environment: e.target.value })}
                    className="form-control  rounded"
                  >
                    <option value="sandbox">Sandbox</option>
                    <option value="live">Live</option>
                  </select>
                </Col> */}
              
            <div className="mt-4">
            
              <div className="d-flex  flex-lg-row align-items-start lg:align-items-center">
              <div className="form-check ps-1">
  <input
    type="radio"
    id="sandbox"
    name="environment"
    value="Sandbox"
    checked={formData.environment === "Sandbox"}
    onChange={(e) =>
      setFormData({
        ...formData,
        environment: e.target.value,
        environment_action: 0,
      })
    }
    className="form-check-input"
  />
  <label htmlFor="sandbox" className="form-check-label">Sandbox</label>
</div>
<div className="form-check">
  <input
    type="radio"
    id="live"
    name="environment"
    value="Live"
    checked={formData.environment === "Live"}
    onChange={(e) =>
      setFormData({
        ...formData,
        environment: e.target.value,
        environment_action: 1,
      })
    }
    className="form-check-input"
  />
  <label htmlFor="live" className="form-check-label">Live</label>
</div>


              </div>
            </div>
            <Row className="align-items-center g-2 mb-4">
                <Col xs="12" sm="6">
                  
                  <label className="form-label" htmlFor="clientKey">
                  Client Key
                  </label>
                  <input
                      type="text"
                      className="form-control"
                      value={formData.client_key}
                      onChange={(e) => setFormData({ ...formData, client_key: e.target.value })}
                      placeholder="Enter Client Key"
                      name="clientKey"
                      required
                  />
                  
                </Col>

                <Col xs="12" sm="6">
                 <label className="form-label" htmlFor="secretKey">
                    Secret Key
                  </label>
                  <input
                    type="text"
                    name="secretKey"
                    value={formData.secret_key}
                    onChange={(e) => setFormData({ ...formData, secret_key: e.target.value })}
                    className="form-control"
                    placeholder="Enter Secret Key"
                    required
                  />
                  
                </Col>
              </Row>
                <Col size="12" className="d-flex justify-start">
                  <Button color="primary" type="submit" disabled={loading}>
                    <Icon className="plus"></Icon>
                    <span className="mx-auto px-0">
                      {modalType === "add" ? "Add" : "Update"}
                    </span>
                    {loading && <PuffLoader speedMultiplier="2" size="30px" color={"#39ff00"} />}
                  </Button>
                </Col>
              </Row>
            </form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default PaymentListModels;
